import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="user-profile-page-wrapper container p-0">
      <h1>Profile</h1>
      <div className="info-fields">
        <div className="row">
          <div className="col-md-6">
          <div className="single-field">
          <label htmlFor="name">Name:</label>
          <input type="text" disabled value={user?.name}/>
          </div>
          
        </div>
        <div className="col-md-6">
          <div className="single-field">
          <label htmlFor="name">Email:</label>
          <input type="text" disabled value={user?.email}/>
          </div>
          </div>
          <div className="col-md-6">
          <div className="single-field role">
          <label htmlFor="name">Role:</label>
          <input type="text"  disabled value={user?.role}/>
          </div>
          </div>
          </div>
          
          <div className="backbtn">
            <Link to={"/"}> <FaArrowLeft /> Back</Link>
          </div>
        </div>
        
        
        <div className="single-field">
      </div>
    </div>
  )
};

export default Profile;
