import { Button,Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./common/Sidebar";
import { userLogoutAsyncThunk } from "../redux/pagesSlices/authSlice";
import bellIcon from "../assets/images/bellIcon.svg"
import homeIcon from "../assets/images/homeIcon.svg"
import brandsContentImage from "../assets/images/brandsContentImage.png"
import { IoMdHome } from "react-icons/io";
import { FaUser, FaWarehouse } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import userAvatar from "../assets/images/avatar.png"
import logo from "../assets/images/brand-logo.png"

export function NavigationBar({children}) {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const router = useNavigate();
  const logout = () => {
    dispatch(userLogoutAsyncThunk({ router }))
  };
  
  return (
    <>
<nav className={`${location.pathname === "/login" || location.pathname === "/signup" ? "d-none" : ""} navbar navbar-expand-lg navbar-light `}>
  <div className="container-fluid">
    <Link className="navbar-brand" to="/">
    <img src={brandsContentImage} alt="brand " />
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
      <ul className="navbar-nav">
        {/* <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to={'/'}>
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={'/homepage'}>
            Warehouses
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={'/'}>
            Pricing
          </Link>
        </li> */}
      </ul>
      <div className="dashboard-header-btns d-flex gap-3">
  <Dropdown>
    <Dropdown.Toggle className="dashboard-header-btn">
      <img src={bellIcon} alt="bell" />
      <span className="header-btn-badge">0</span>
    </Dropdown.Toggle>
    <Dropdown.Menu className="notifications-dropdown">
      <Dropdown.Item href="#/action-1">
        <ul className="dashboard-notification d-flex flex-column gap-3">
          <p>No notifications found.</p>
        </ul>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>

  <Dropdown>
    <Dropdown.Toggle className="avatar-rounded-circle">
      <img src={userAvatar} alt="homeIcon" />
    </Dropdown.Toggle>
    <Dropdown.Menu className="notifications-dropdown">
      <Link to={"/"} class="dashboard-profile-item dropdown-item d-flex gap-2" href="/dashboard">
      <IoMdHome />
      <span>Home</span>
      </Link>
      {/* <Link to={"/warehouse"} class="dashboard-profile-item dropdown-item d-flex gap-2" href="/dashboard">
      <FaWarehouse />
      <span>Add Warehouse</span>
      </Link>
      <Link to={"/warehouse-listing"} class="dashboard-profile-item dropdown-item d-flex gap-2" href="/dashboard">
      <FaWarehouse />
      <span>My Warehouses</span>
      </Link> */}
      <Link to={"/profile"} class="dashboard-profile-item dropdown-item d-flex gap-2" href="/dashboard">
      <FaUser />
      <span>Profile</span>
      </Link>
      <button onClick={logout} class="dashboard-profile-item dropdown-item d-flex gap-2" href="/dashboard">
      <BiLogOut />
      <span>Logout</span>
      </button>
    </Dropdown.Menu>
  </Dropdown>
</div>

      {/* <div>
        {user?
        <div className="dashboard-header-btns d-flex gap-3">
        <Button>
          <Link className="text-decoration-none text-white" to={'/profile'}>Profile</Link>
        </Button>
        <Button onClick={logout}>
          <Link className="text-decoration-none text-white" to={'/profile'}>Logout</Link>
        </Button>
        
        </div>
        :<>
      <Button className="mx-3">
        <Link className="text-decoration-none text-white" to={'/login'}>Login</Link>
      </Button>
      <Button>
        <Link className="text-decoration-none text-white" to={'/signup'}>Sign up</Link>
      </Button>
        </>
        }
      </div> */}
    </div>
  </div>
</nav>
</>
  );
}
