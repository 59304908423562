import { BrowserRouter, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.scss";
import SignUpForm from "./components/Signup";
import LoginForm from "./components/Signin";
import ProtectedLayout from "./Layouts/ProtectedLayout";
import AuthLayout from "./Layouts/AuthLayout";
import Home from "./Pages/Home";
import Models from "./components/modals";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import Profile from "./Pages/Profile";
import WareHouseForm from "./components/WarehouseForm";
import AddWarehouse from "./components/pages/AddWarehouse";
import Homepage from "./components/pages/Homepage";
import WareHouseListing from "./components/pages/WarehouseListing";
import { authenticateAsyncThunk } from "./redux/pagesSlices/authSlice";
import { useDispatch } from "react-redux";

function App() {
  const d = useDispatch();
  useEffect(() => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("data-theme", "light");
    d(authenticateAsyncThunk())
  }, [d]);
  return (
    <>
      <BrowserRouter>
        <Models />
        <ToastContainer />
        <Router />
      </BrowserRouter>
    </>
  );
}

function Router() {
  let element = useRoutes([
    {
      element: <AuthLayout />,
      children: [
        {
          path: "/signup",
          element: <SignUpForm />,
        },
        { path: "/login", element: <LoginForm /> },
        // {path:"/homepage",element: <Homepage />},
        // {path:"/warehouse",element: <AddWarehouse />},
        // {path:"/warehouse-listing",element: <WareHouseListing />}

      ],
    },
    {
      path: "/",
      element: <ProtectedLayout />,
      children: [
        { path: "/", element: <Homepage /> },
        { path: "/profile", element: <Profile /> },
        {path:"/homepage",element: <Homepage />},
        {path:"/warehouse",element: <AddWarehouse />},
        {path:"/warehouse-listing",element: <WareHouseListing />}
      ],
    },
  ]);

  return element;
}

export default App;
