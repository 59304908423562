export const basePath = process.env.REACT_APP_basePath ?? "http://localhost:8000/api/"

export const APIurls = {
  login: "auth/login",
  refreshTokens: "auth/refresh-tokens",
  register: "auth/register",
  logout: "auth/logout",
  authenticate: "auth/authenticate",
  revokeToken: "auth/customToken/revoke",
  warehouse:"warehouse"
};
export const pages = ['Products', 'Pricing', 'Blog'];
export const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];