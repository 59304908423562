import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleModel } from "../../redux/layoutSlices/modelSlice";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Button, Modal } from "react-bootstrap";

export default function Confirmation() {
  const state = useSelector((state) => state.model?.modelState?.confirmation);
  console.log("🚀 ~ Confirmation ~ state:", state);
  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.confirmation,
  );
  console.log("modelsArgs", modelsArgs);
  const d = useDispatch();
  // close model
  const closeModel = () =>
    d(handleModel({ model: "confirmation", state: false }));
  const proceed = () => {
    if (modelsArgs?.callBack) modelsArgs.callBack();
    closeModel();
  };

  return (
    <Modal show={state} size="md" onHide={closeModel}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <HiOutlineExclamationCircle
            className="d-block text-secondary mx-auto mb-4"
            style={{ width: "56px", height: "56px" }}
          />
          <h3 className="text-body-secondary mb-4">
            Are you sure you want to delete this product?
          </h3>
          <div className="d-flex justify-center gap-4">
            <Button variant="danger" onClick={proceed}>
              {"Yes, I'm sure"}
            </Button>
            <Button variant="secondary" onClick={closeModel}>
              No, cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
