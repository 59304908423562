import Sidebar from "../common/Sidebar";
import WareHouseForm from "../WarehouseForm";

export default function AddWarehouse(){
    return(
        <div className="add-warehouse-page-wrapper container p-0">
            <div className="page-title">
                <h1>Add Warehouse</h1>
            </div>
            <div className="page-controls">

            </div>
            <WareHouseForm />
        </div>
    )

        
}