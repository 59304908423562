import { Formik } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { userLoginAsyncThunk } from "../redux/pagesSlices/authSlice";
export default function SignInSide() {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().min(8).required("Password is required"),
  })
  const router = useNavigate();
  const dispatch = useDispatch();
  const handleUserLogin = async(values)=>{
    console.log("values",values);
    const response =  await dispatch(userLoginAsyncThunk({
      email : values?.email,
      password : values?.password,
      router
    }))
    
  }
  return (
<div className="container">
  <div className="row">
    <div className="col-md-6 offset-md-3">
      <h2 className="text-center text-dark mt-5">Login Form</h2>
      <div className="card my-5">
        <Formik 
          initialValues={{
            email:"",
            password : ""
          }}
          validationSchema={validationSchema}
          onSubmit={handleUserLogin}
        >{({values,handleChange,handleBlur,handleSubmit,errors,touched}) => (
          <form className="card-body cardbody-color p-lg-5">
          <div className="text-center">
            <img
              src="https://cdn.pixabay.com/photo/2016/03/31/19/56/avatar-1295397__340.png"
              className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
              width="200px"
              alt="profile"
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange("email")}
              onBlur={handleBlur("email")}
              aria-describedby="emailHelp"
              placeholder="Email address"
            />
            {touched && errors.email && <span className="text-danger">{errors.email}</span>}
          </div>
          <div className="mb-3">
            <input
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange("password")}
              onBlur={handleBlur("password")}
              className="form-control"
              id="password"
              placeholder="password"
            />
            {touched && errors.password && <span className="text-danger">{errors.password}</span>}
          </div>
          <div className="text-center">
            <Button type="submit" onClick={handleSubmit} className="px-5 mb-5 w-100 ">
              Login
            </Button>
          </div>
          <div id="emailHelp" className="form-text text-center mb-5 text-dark">
            Not Registered?{" "}
            <Link to="/signup" className="text-dark fw-bold">
              Create an Account
            </Link>
          </div>
        </form>
        )}
        
        </Formik>
        
      </div>
    </div>
  </div>
</div>
  );
}
