import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { NavigationBar } from "../components/Layout";
import { useSelector } from "react-redux";
import Sidebar from "../components/common/Sidebar";

const AuthLayout = () => {
  const auth = useSelector((state) => state.auth);
  const loading = useSelector(
    (state) => state.auth.loadings["authenticateAsyncThunk"],
  );
  const user = auth.user;

  if (loading) return <div>Loading...</div>;
  if (user) return <Navigate to={"/"} />;
  return (
    <>
      <NavigationBar />
      <div className="main-dashboard-wrapper">
        <div className="sidebar-main-wrapper">
          <Sidebar />
        </div>
        <div className="dashboard-body-wrapper">
        <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
