import { useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { deteleWarehouseAsyncThunk, getWarehousesAsyncThunk } from "../../redux/pagesSlices/warehouseSlice";
import EditWarehousemodal from "../modals/EditWarehousemodal";

export default function WareHouseListing() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWarehousesAsyncThunk({}));
  }, [dispatch]);

  const wareHouses = useSelector((state) => state.warehouse);
  const wareHouseListing = wareHouses?.warehouses?.results;

  
  // console.log("wareHouseListing", wareHouseListing);
  

  const handleDeleteWarehouse = async (id) => {
    await dispatch(deteleWarehouseAsyncThunk(id));
  };

  const [warehouseModalShow, setWarehouseModalShow] = useState(false);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  // console.log("selectedWarehouseId", selectedWarehouseId);
  

  const handleWarehouseModalShow = (id) => {
    if (selectedWarehouseId === id) return; // Prevent multiple modals for the same id
    setSelectedWarehouseId(id);
    setWarehouseModalShow(true);
  };

  const handleWarehouseModalHide = () => {
    setSelectedWarehouseId(null); // Clear the selected ID
    setWarehouseModalShow(false);
  };

  const defaultListing = [
    {
      id: 1,
      name: "Warehouse 1",
      location: {
        country: "USA",
        street: "123 Main St",
        city: "New York",
        zipCode: 10001,
      },
      address: "123 Main St, New York, USA",
      capacity: { total: 100 },
      pricing: { fulfillmentFee: 10 },
      services: { pickAndPack: true },
    },
    {
      id: 2,
      name: "Warehouse 2",
      location: {
        country: "USA",
        street: "456 Elm St",
        city: "New York",
        zipCode: 10002,
      },
      address: "456 Elm St, New York, USA",
      capacity: { total: 200 },
      pricing: { fulfillmentFee: 15 },
      services: { pickAndPack: false },
    },
    {
      id: 3,
      name: "Warehouse 3",
      location: {
        country: "USA",
        street: "789 Oak St",
        city: "New York",
        zipCode: 10003,
      },
      address: "789 Oak St, New York, USA",
      capacity: { total: 150 },
      pricing: { fulfillmentFee: 20 },
      services: { pickAndPack: true },
    },
  ];

  return (
    <div className="warehouse-listing">
      <div className="container">
        <div className="warehouse-listing-table">
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Location</th>
                <th>Address</th>
                <th>Zip Code</th>
                <th>Quantity</th>
                <th>Fulfillment Fee</th>
                <th>Packing Labels</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(wareHouseListing?.length ? wareHouseListing : defaultListing).map((item, index) => (
                <tr key={item.id || index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.location.country}</td>
                  <td>
                    {item.location.street}, {item.location.city}, {item.location.country}
                  </td>
                  <td>{item?.location?.zipCode}</td>
                  <td>{item?.capacity?.total}</td>
                  <td>${item?.pricing?.fulfillmentFee}</td>
                  <td>{item?.services?.pickAndPack ? "Yes" : "No"}</td>
                  <td className="action-buttons">
                    <button onClick={() => handleWarehouseModalShow(item.id)}>
                      <FaEdit />
                    </button>
                    
                    <button onClick={() => handleDeleteWarehouse(item.id)}>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <EditWarehousemodal
                      id={selectedWarehouseId}
                      warehouseModalShow={warehouseModalShow}
                      handleWarehouseModalHide={handleWarehouseModalHide}
                    />
        </div>
      </div>
    </div>
  );
}
