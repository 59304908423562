import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getWarehouseAsyncThunk, updateWarehouseAsyncThunk } from "../../redux/pagesSlices/warehouseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { countries } from 'countries-list';
import * as Yup from "yup";
const countryArray = Object.values(countries);

export default function EditWarehousemodal({warehouseModalShow,handleWarehouseModalHide,id}) {
   // console.log("id Received", id);
   
   const wareHouseValidationSchema = Yup.object().shape({
      name: Yup.string(),
      location: Yup.object().shape({
          street: Yup.string(),
          city: Yup.string().required("City is required"),
          state: Yup.string().required("State is required"),
          zipCode: Yup.string(),
          country: Yup.string().required("Country is required"),
      }),
      capacity: Yup.object().shape({
          total: Yup.number().required("Total capacity is required"),
          used: Yup.number().required("Used capacity is required"),
      }),
      pricing: Yup.object().shape({
          storagePerUnit: Yup.number().required("Storage per unit is required"),
          fulfillmentFee: Yup.number().required("Fulfillment fee is required"),
      }),
      services: Yup.object().shape({
          pickAndPack: Yup.string(),
          labeling: Yup.string(),
          expeditedShipping: Yup.string(),
      })        
  })

    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getWarehouseAsyncThunk(id));
        }
    }, [id]);

    const warehouse = useSelector((state)=>state.warehouse.warehouse)
   //  console.log("🚀 ~ file: EditWarehousemodal.jsx:EditWarehousemodal ~ warehouse:", warehouse);
    
    const handleUpdateWarehouse = (values) => {
      //   console.log("Values", values);
        dispatch(updateWarehouseAsyncThunk({
            id,
            data: values
        }))
    }
    return(
        <Modal size="lg" show={warehouseModalShow} className="modal-wrapper" onHide={handleWarehouseModalHide} centered aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton></Modal.Header>
            
                <Modal.Body>
                    <Formik
                        initialValues={{
                            name : warehouse?.name || "",
                            location : {
                                street : warehouse?.location?.street || "",
                                city : warehouse?.location?.city || "",
                                country : warehouse?.location?.country || "",
                                zipCode : warehouse?.location?.zipCode || "",
                                state : warehouse?.location?.state || ""
                            },
                            capacity: {
                                total: warehouse?.capacity?.total || 0,
                                used: warehouse?.capacity?.used || 0,
                            },
                            pricing: {
                                storagePerUnit: warehouse?.pricing?.storagePerUnit || 0,
                                fulfillmentFee: warehouse?.pricing?.fulfillmentFee || 0,
                            },
                            services: {
                                pickAndPack: warehouse?.services?.pickAndPack || "false",
                                labeling: warehouse?.services?.labeling || "false",
                                expeditedShipping: warehouse?.services?.expeditedShipping || "false",
                            },
                        }}
                        enableReinitialize
                        onSubmit={handleUpdateWarehouse}
                        validationSchema={wareHouseValidationSchema}
                    >
                        {({values,handleChange,handleBlur,handleSubmit,errors,touched})=>(
                            <div className="edit-warehouse-form">
                            <div className="container">
                               <div className="form-section">
                                  <div className="single-field">
                                     <label htmlFor="">Warehouse Name</label>
                                     <input type="text" value={values.name} onChange={handleChange("name")} name="name" handleBlur={handleBlur("name")}/>
                                    {errors?.name && touched?.name && <div className="error">{errors?.name}</div>}
                                  </div>
                               </div>
                               <div className="form-section">
                                  <h2>Address *</h2>
                                  <div className="row">
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">street</label>
                                           <input type="text" value={values.location?.street} name="location.street" onChange={handleChange} onBlur={handleBlur}/>  
                                        </div>
                                     </div>
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">city</label>
                                           <input type="text" value={values.location.city} name="location.city" onChange={handleChange} onBlur={handleBlur}/>  
                                          {errors.location?.city && touched.location?.city && <div className="error">{errors.location?.city}</div>}
                                        </div>
                                     </div>
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">country</label>
                                           <select name="location.country" value={values.location.country} onChange={handleChange} onBlur={handleBlur} id="">
                                              {countryArray.map((country) => (
                                              <option value={country.name}>{country.name}</option>
                                              ))}
                                           </select>
                                           {errors.location?.country && touched.location?.country && <div className="error">{errors.location?.country}</div>}
                                        </div>
                                     </div>
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">zip code</label>
                                           <input type="text" value={values.location?.zipCode} name="location.zipCode" onChange={handleChange} onBlur={handleBlur}/>  
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="form-section">
                                  <h2>Pricing *</h2>
                                  <div className="row">
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">Storage per unit($)</label>
                                           <input type="number" value={values.pricing.storagePerUnit} name="pricing.storagePerUnit" onChange={handleChange} onBlur={handleBlur}/>  
                                              {errors.pricing?.storagePerUnit && touched.pricing?.storagePerUnit && <div className="error">{errors.pricing?.storagePerUnit}</div>}
                                        </div>
                                     </div>
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">Fullfillment Fee($)</label>
                                           <input type="number" value={values.pricing.fulfillmentFee} name="pricing.fulfillmentFee" onChange={handleChange} onBlur={handleBlur}/>  
                                              {errors.pricing?.fulfillmentFee && touched.pricing?.fulfillmentFee && <div className="error">{errors.pricing?.fulfillmentFee}</div>}
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="form-section">
                                  <h2>Capacity *</h2>
                                  <div className="row">
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">Total Quantity</label>
                                           <input type="number" value={values.capacity.total} name="capacity.total" onChange={handleChange} onBlur={handleBlur}/>  
                                              {errors.capacity?.total && touched.capacity?.total && <div className="error">{errors.capacity?.total}</div>}
                                        </div>
                                     </div>
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">Quantity Used</label>
                                           <input type="number" value={values.capacity.used} name="capacity.used" onChange={handleChange} onBlur={handleBlur}/>  
                                              {errors.capacity?.used && touched.capacity?.used && <div className="error">{errors.capacity?.used}</div>}
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="form-section">
                                  <h2>Services *</h2>
                                  <div className="row">
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">Pick and Pack</label>
                                           <select name="services.pickAndPack" onChange={handleChange} onBlur={handleBlur} id="" value={values.services.pickAndPack}>
                                              <option value="true">Yes</option>
                                              <option value="false">No</option>
                                           </select>
                                           {errors.services?.pickAndPack && touched.services?.pickAndPack && <div className="error">{errors.services?.pickAndPack}</div>}
                                        </div>
                                     </div>
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">Labeling</label>
                                           <select name="services.labeling" onChange={handleChange} onBlur={handleBlur} id="" value={values.services.labeling}>
                                              <option value="true">Yes</option>
                                              <option value="false">No</option>
                                           </select>
                                           {errors.services?.labeling && touched.services?.labeling && <div className="error">{errors.services?.labeling}</div>}
                                        </div>
                                     </div>
                                     <div className="col-md-6">
                                        <div className="single-field">
                                           <label htmlFor="">Expedited Shipping</label>
                                           <select name="services.expeditedShipping" onChange={handleChange} onBlur={handleBlur} id="" value={values.services.expeditedShipping}>
                                              <option value="true">Yes</option>
                                              <option value="false">No</option>
                                           </select>
                                           {errors.services?.expeditedShipping && touched.services?.expeditedShipping && <div className="error">{errors.services?.expeditedShipping}</div>}
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <button type="submit" onClick={handleSubmit} className="submit-button">
                               Update
                               </button>
                            </div>
                         </div>
                        )}
                    
                    </Formik>
                </Modal.Body>
        </Modal>
    )
}