import { createAsyncThunk, isAnyOf, createSlice } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import { catchAsync, handleLoadingErrorParamsForAsycThunk, reduxToolKitCaseBuilder } from "../../helpers/detectError";
import { authenticateAsyncThunk } from "./authSlice";
import { useDispatch } from "react-redux";


import {toast} from "react-toastify"

export const getWarehousesAsyncThunk = createAsyncThunk(
    "warehouse/getWarehousesAsyncThunk",
    catchAsync(async (params, _) => {
        const response = await ApiRequests.getWarehouses(params);
        return response?.data;
    })
);

export const getWarehouseAsyncThunk = createAsyncThunk(
    "warehouse/getWarehouseAsyncThunk",
    catchAsync(async (id, _) => {
        const response = await ApiRequests.getWarehouse(id);
        return response?.data;
    })
)

export const createWarehouseAsyncThunk = createAsyncThunk(
    "warehouse/createWarehouseAsyncThunk",
    catchAsync(async ({ data, callback }, { dispatch, getState }) => {
        const response = await ApiRequests.createWarehouse(data); 
               
        if (response.status === 201) {
            console.log("response status",response.status);
            
            toast.success("Warehouse created successfully")
        }
        if (callback) callback();
        // let params = {}
        // let state1 = getState().listings
        // if (state1.search) params.name = state1.search;
        // if (state1.order) params.sortBy = `name:${state1.order}`;
        // dispatch(getWarehousesAsyncThunk({ ...params, populate: "provider_id" }))
        return response?.data;
    })
)

export const updateWarehouseAsyncThunk = createAsyncThunk(
    "warehouse/updateWarehouseAsyncThunk",
    async ({ id, data, callback }, { dispatch, getState }) => {
        try {
            const response = await ApiRequests.updateWarehouse(id, data);
            if (response.status === 200) {
                toast.success("Warehouse updated successfully");
            }
            if (callback) callback();
            let state1 = getState().listings;
            dispatch(authenticateAsyncThunk());
            return response?.data;
        } catch (error) {
            toast.error("Failed to update warehouse");
            console.error("Update Error:", error);
            throw error;
        }
    }
);

export const deteleWarehouseAsyncThunk = createAsyncThunk(
    "warehouse/deleteWarehouseAsyncThunk",
    catchAsync(async (id, { dispatch, getState }) => {
        const response = await ApiRequests.deleteWarehouse(id);
        if (response.status === 204) {
            toast.success("Warehouse deleted successfully")
            let params = {}
            // let state = getState().listings;
            // if (state.search) params.name = state.search;
            // if (state.order) params.sortBy = `name:${state.order}`;
            dispatch(getWarehousesAsyncThunk({ ...params}))
        } else {
            toast.error(response.error)
        }
        return id;



    })
)

const initialState = {
    warehouses: {
        page: 1,
        warehouses: [],
        totalPages: 1,
    },
    listings: {
        page: 1,
        results: [],
        totalPages: 1,
    },
    warehouse: null,
    errors: {},
    loadings: {},
    errorMessages: {},
    errorCodes: {},
    paramsForThunk: {},
    search: null,
    categoryId: null,
    categories: [],
    order: "asce",
}

const warehouseSlice = createSlice({
    name: "warehouses",
    initialState,
    reducers: {
        setSearchValue(state, action) {
            state.search = action.payload;
        },
        setCategoryValue(state, action) {
            state.categoryId = action.payload;
        },
        setOrderValue(state, action) {
            state.order = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(updateWarehouseAsyncThunk.fulfilled, (state, action) => {
                state.warehouse = action.payload;
            })
            .addCase(getWarehousesAsyncThunk.pending, (state, action) => {
                if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
                    state.warehouses = {
                        page: 1,
                        results: [],
                        totalPages: 1,
                    };
                }
            })
            .addCase(getWarehousesAsyncThunk.fulfilled, (state, action) => {
                if (action.payload?.page > 1) {
                    state.warehouses = {
                        ...action.payload,
                        warehouses: state?.warehouses?.results.concat(action?.payload?.results),
                    };
                } else {
                    state.warehouses = action.payload;
                }
            })
            .addCase(getWarehouseAsyncThunk.fulfilled, (state, action) => {
                if (action.payload?.page > 1) {
                    state.warehouse = {
                        ...action.payload,
                        results: state?.warehouse?.results.concat(action?.payload?.results),
                    };
                } else {
                    state.warehouse = action.payload;
                }
            })
            .addCase(deteleWarehouseAsyncThunk.fulfilled, (state, action) => {
                const deletedId = action.payload; // The ID of the deleted warehouse
            
                // Update the `state.warehouses`
                if (state.warehouses?.results) {
                    state.warehouses = {
                        ...state.warehouses,
                        totalResults: state.warehouses.totalResults - 1,
                        results: state.warehouses.results.filter((e) => e.id !== deletedId),
                    };
                }
            
                // Update the `state.warehousesCount` if applicable
                if (state.warehouseCount?.results) {
                    state.warehouseCount = {
                        ...state.warehouseCount,
                        totalResults: state.warehouseCount.totalResults - 1,
                        results: state.warehouseCount.results.filter((e) => e.id !== deletedId),
                    };
                }
            })
            .addMatcher(
                isAnyOf(
                    ...reduxToolKitCaseBuilder([
                        createWarehouseAsyncThunk,
                        getWarehouseAsyncThunk,
                        getWarehousesAsyncThunk,
                        updateWarehouseAsyncThunk,
                        deteleWarehouseAsyncThunk
                    ])
                ),
                handleLoadingErrorParamsForAsycThunk
            )

    }
})

export default warehouseSlice.reducer
export const { setSearchValue, setOrderValue, setCategoryValue, setLoading } = warehouseSlice.actions