// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import model from "./layoutSlices/modelSlice";
import warehouse from "./pagesSlices/warehouseSlice"
import error from "./errors/handleErrorsAndPayloads";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  error,
  auth,
  model,
  warehouse,
});

export default rootReducer;
