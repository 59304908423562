import { Formik } from "formik"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import { createWarehouseAsyncThunk } from "../redux/pagesSlices/warehouseSlice";
import { countries } from 'countries-list';
import Sidebar from "./common/Sidebar";

const countryArray = Object.values(countries);

export default function WareHouseForm() {
    

    const wareHouseValidationSchema = Yup.object().shape({
        name: Yup.string(),
        location: Yup.object().shape({
            street: Yup.string(),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            zipCode: Yup.string(),
            country: Yup.string().required("Country is required"),
        }),
        capacity: Yup.object().shape({
            total: Yup.number().required("Total capacity is required"),
            used: Yup.number().required("Used capacity is required"),
        }),
        pricing: Yup.object().shape({
            storagePerUnit: Yup.number().required("Storage per unit is required"),
            fulfillmentFee: Yup.number().required("Fulfillment fee is required"),
        }),
        services: Yup.object().shape({
            pickAndPack: Yup.string(),
            labeling: Yup.string(),
            expeditedShipping: Yup.string(),
        })        
    })
    const dispatch = useDispatch();
    const handleAddWarehouse = async (values, formControls) => {
        console.log("Values", values);
        formControls.resetForm();
        
        dispatch(createWarehouseAsyncThunk({
            data: values,
        }))
        formControls.resetForm();
    }
    return (
        <div className="main-page-wrapper">
        
        <div className="warehouse-form-wrapper">
            <Formik
                initialValues={{
                    name: "",
                    location: {
                        street: "",
                        city: "",
                        state: "",
                        zipCode: "",
                        country: ""
                    },
                    capacity: {
                        total: 0,
                        used: 0,
                    },
                    pricing: {
                        storagePerUnit: 0,
                        fulfillmentFee: 0,
                    },
                    services: {
                        pickAndPack: "",
                        labeling: "",
                        expeditedShipping: "",
                    },

                }}
                onSubmit={handleAddWarehouse}
                validationSchema={wareHouseValidationSchema}
            >
                {({ values, handleBlur, handleChange, handleSubmit,errors,touched }) => (
                    <div className="form-wrapper">
                        <div className="single-field flex flex-col gap-2">
                            <label htmlFor="">Enter Warehouse Name</label>
                            <input type="text" placeholder="Enter Warehouse Name" value={values.name} name="name" onBlur={handleBlur} onChange={handleChange} />
                            {errors?.name && touched?.name && <span className="text-danger">{errors?.name}</span>}
                        </div>
                        <div className="form-section">
                            <h2>Address *</h2>
                            <div className="row">
                                <div className="single-field col-md-4 mb-3">
                                    <label htmlFor="">Street</label>
                                    <input type="text" placeholder="Street Address" value={values.location.street} name="location.street" onChange={handleChange} onBlur={handleBlur} />
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">State</label>
                                    <input type="text" placeholder="State" value={values.location.state} name="location.state" onChange={handleChange} onBlur={handleBlur} />
                                    {errors.location?.state && touched.location?.state && <span className="text-danger">{errors.location?.state}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Zip Code</label>
                                    <input type="number" placeholder="Zip Code" value={values.location.zipCode} name="location.zipCode" onChange={handleChange} onBlur={handleBlur} />
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Country</label>
                                    <select name="location.country" value={values.location.country} onChange={handleChange} onBlur={handleBlur} id="">
                                        <option value="">Select Country</option>
                                        {countryArray.map((country) => (
                                            <option value={country.name}>{country.name}</option>
                                        ))}
                                    </select>
                                    {errors.location?.country && touched.location?.country && <span className="text-danger">{errors.location?.country}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">City</label>
                                    <input type="text" placeholder="City" value={values.location.city} name="location.city" onChange={handleChange} onBlur={handleBlur} />
                                    {errors.location?.city && touched.location?.city && <span className="text-danger">{errors.location?.city}</span>}
                                </div>
                            </div>

                        </div>
                        <div className="form-section">
                            <h2>Capacity *</h2>
                            <div className="row">
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Total Quantity *</label>
                                    <input type="number" name="capacity.total" value={values.capacity.total} onChange={handleChange} onBlur={handleBlur} placeholder="Storage Per Unit" />
                                        {errors.capacity?.total && touched.capacity?.total && <span className="text-danger">{errors.capacity?.total}</span>}
                                </div>
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Quantity Used *</label>
                                    <input type="number" name="capacity.used" value={values.capacity.used} onChange={handleChange} onBlur={handleBlur} placeholder="Fullfillment fee" />
                                        {errors.capacity?.used && touched.capacity?.used && <span className="text-danger">{errors.capacity?.used}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="form-section">
                            <h2>Pricing *</h2>
                            <div className="row">
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Storage Per Unit *</label>
                                    <input type="number" name="pricing.storagePerUnit" value={values.pricing.storagePerUnit} onChange={handleChange} onBlur={handleBlur} placeholder="Storage Per Unit" />
                                        {errors.pricing?.storagePerUnit && touched.pricing?.storagePerUnit && <span className="text-danger">{errors.pricing?.storagePerUnit}</span>}
                                </div>
                                <div className="single-field col-md-6">
                                    <label htmlFor="">Fullfillment fee *</label>
                                    <input type="number" name="pricing.fulfillmentFee" value={values.pricing.fulfillmentFee} onChange={handleChange} onBlur={handleBlur} placeholder="Fullfillment fee" />
                                        {errors.pricing?.fulfillmentFee && touched.pricing?.fulfillmentFee && <span className="text-danger">{errors.pricing?.fulfillmentFee}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="form-section">
                            <h2>Services *</h2>
                            <div className="row">
                                <div className="single-field col-md-4 mb-3">
                                    <label htmlFor="">PickPack? *</label>
                                    <select name="services.pickAndPack" onChange={handleChange} onBlur={handleBlur} id="" value={values.services.pickAndPack}>
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    {errors.services?.pickAndPack && touched.services?.pickAndPack && <span className="text-danger">{errors.services?.pickAndPack}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Labeling? *</label>
                                    <select name="services.labeling" onChange={handleChange} onBlur={handleBlur} id="" value={values.services?.labeling}>
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    {errors.services?.labeling && touched.services?.labeling && <span className="text-danger">{errors.services?.labeling}</span>}
                                </div>
                                <div className="single-field col-md-4">
                                    <label htmlFor="">Expedited Shipping? *</label>
                                    <select name="services.expeditedShipping" onChange={handleChange} onBlur={handleBlur} id="" value={values.services.expeditedShipping}>
                                        <option value="">Select Option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                    {errors.services?.expeditedShipping && touched.services?.expeditedShipping && <span className="text-danger">{errors.services?.expeditedShipping}</span>}
                                </div>
                            </div>
                        </div>

                        <button className="submit-button" type="submit" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                )}
            </Formik>
        </div>
        </div>
        
    )
}